@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@font-face {
  font-family: 'Product Sans';
  src: url("/static/media/Product Sans Regular.d1653840.ttf") format('ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url("/static/media/Product Sans Bold.53df38d5.ttf") format('ttf');
  font-weight: bold;
}


/*@font-face {
  font-family: 'SF';
  src: url('../fonts/SF-Pro-Display-Ultralight.otf');
  font-weight: 100;
}
@font-face {
  font-family: 'SF';
  src: url('../fonts/SF-Pro-Display-Thin.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'SF';
  src: url('../fonts/SF-Pro-Display-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'SF';
  src: url('../fonts/SF-Pro-Text-Bold.otf');
  font-weight: 700;
}

*/

